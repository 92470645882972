<template>
    <div class="data-detail-info">
        <detail-form
            v-if="type == 'edit'"
            :stuInfo="stuInfo"
            :listQuery="listQuery"
            :checked="checked"
        ></detail-form>
        <class-info v-if="type == 'info'"  :stuInfo="stuInfo"></class-info>
        <stu-info :stuInfo="stuInfo" :stuSel="stuSel"></stu-info>
    </div>
</template>
<script>
import DetailForm from "./DetailForm.vue";
import ClassInfo from "./ClassInfo.vue";
import StuInfo from "./StuInfo.vue";
export default {
    name: "DataDetailInfo",
    components: {
        DetailForm,
        ClassInfo,
        StuInfo,
    },
    props: {
        type: String,
        stuInfo: Object,
        checked: Boolean,
        listQuery: Object,
        stuSel: Array,
    },
};
</script>
<style lang="scss" scoped>
.data-detail-info {
    height: 68px;
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    box-sizing: border-box;
    padding: 0 36px;
}
</style>
