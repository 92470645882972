<template>
    <div class="btn-option">
        <el-button @click="cancellation">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
    </div>
</template>
<script>
export default {
    name: "BtnOption",
    methods: {
        save () {
            this.$emit('save')
        },
        cancellation () {
            this.$emit('cancellation')
        }
    }
}
</script>
<style lang="scss" scoped>
    .btn-option {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
