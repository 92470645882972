<template>
    <div class="detail-form">
        <el-input v-model="listQuery.studentName" placeholder="输入学生姓名" style="width: 170px;" clearable></el-input>
        <el-input v-model="listQuery.studyNo" placeholder="输入学号" style="width: 170px; margin-left: 10px;" clearable></el-input>
        <el-button type="primary" style="margin-left: 10px;" @click="inquiry">查询</el-button>
        <el-button @click="reset">重置</el-button>
        <el-checkbox v-model="checkedAll" style="margin-left: 36px;" @change="changeAllSel">全选</el-checkbox>
    </div>
</template>
<script>
export default {
    name: "DetailForm",
    props: {
        listQuery: Object,
        checked: Boolean
    },
    data () {
        return {
            input: '',
            checkedAll: false
        }
    },
    methods: {
        initAss () {
            this.checkedAll = this.checked;
        },
        changeAllSel () {
            this.$eventDispatch('changeAllSel')
        },
        inquiry () {
            this.$eventDispatch('inquiry')
        },
        reset () {
            this.$eventDispatch('reset')
        }
    },
    watch: {
        checked: {
            handler () {
                this.initAss()
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" scoped>

.detail-form /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    border: 1px solid #C8CACC;
    border-radius: 4px;
}
.detail-form /deep/ .el-checkbox__inner::after {
    width: 5px;
    height: 9px;
    left: 6px;
    top: 2px;
}

</style>
