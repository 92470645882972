<template>
    <div class="class-info">
        <!-- <div class="date">
            日期：{{ stuInfo.checkDate }}
        </div>
        <div class="grade">
            年级：2022级
        </div>
        <div class="class">
            班级：G1班
        </div> -->
        <div class="check-time">
            核查时间：{{ stuInfo.createTime  }} ({{ stuInfo.createName }})
        </div>
        <div class="update-time">
            最后更新时间：{{ stuInfo.updateTime }} ({{ stuInfo.updateName }})
        </div>
    </div>
</template>
<script>
export default {
    name: "ClassInfo",
    props: {
        stuInfo: Object,
    },
}
</script>
<style lang="scss" scoped>
    .class-info {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2B2F33;
    }
</style>
