<template>
    <div class="stu-info">
        <img class="peo" :src="peo" alt="人数">
        <div class="due-to">应到人数: <span class="peo">{{ stuInfo.shouldNum }}人</span></div>
        <div class="actual">实到人数: <span class="num">{{ stuSel.length }}</span>人</div>
    </div>
</template>
<script>
export default {
    name: "StuInfo",
    props: {
        stuInfo: Object,
        stuSel: Array,
    },
    computed: {
        peo () {
            return require('@/assets/images/peo.png')
        }
    }
}
</script>
<style lang="scss" scoped>
    .stu-info {
        height: 15px;
        line-height: 15px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2B2F33;
        padding-left: 23px;
        .peo {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
        .due-to {
            height: 15px;
            border-right: 1px solid #2B2F33;
            .peo {
                padding-right: 12px;
            }
        }
        .actual {
            height: 15px;
            padding-left: 13px;
        }
    }
</style>
