<template>
    <div class="data-detail-list" :style="{'height': listHeight}">
        <data-detail-list-item
            v-for="(item, index) in list"
            :key="index"
            :data="item"
            :type="type"
        ></data-detail-list-item>
    </div>
</template>
<script>
import DataDetailListItem from './Item.vue'
export default {
    name: "DataDetailList",
    components: {
        DataDetailListItem
    },
    props: {
        list: Array,
        type: String,
    },
    computed: {
        listHeight () {
            if (this.type == 'info') {
                return 'calc(100vh - 232px)'
            } else {
                return 'calc(100vh - 292px)'
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.data-detail-list {
    overflow: auto;
    box-sizing: border-box;
    padding: 0 36px 13px;
    background-color: #f7f7f7;
}
</style>
