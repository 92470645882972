<template>
    <div class="data-detail-list-item">
        <div class="stu-option">
            <span class="title">{{ data.studentName }}</span>
            <el-checkbox v-model="data.checkStatus" :disabled="type == 'info'" @change="selChange" :true-label="1" :false-label="0"></el-checkbox>
        </div>
        <div class="stu-only-code">
            {{ data.studyNo || '—' }}
        </div>
    </div>
</template>
<script>
export default {
    name: "DataDetailListItem",
    props: {
        data: Object,
        type: String
    },
    data () {
        return {
            checked: false,
        }
    },
    methods: {
        selChange () {
            if (this.type == 'edit') {
                this.$eventDispatch('selChange', this.data)
            }
        }
    },
}
</script>
<style lang="scss" scoped>
    .data-detail-list-item {
        width: 216px;
        height: 83px;
        float: left;
        position: relative;
        box-sizing: border-box;
        padding: 10px 10px 0 20px;
        background: #FFFFFF;
        border-radius: 8px;
        overflow: hidden;
        margin: 0 16px 16px 0;
        .stu-option {
            display: flex;
            justify-content: space-between;
            .title {
                width: 150px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #2B2F33;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .stu-only-code {
            margin-top: 16px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #9DA2A6;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
</style>
